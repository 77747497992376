<template>
  <section class="pt-5">
    <b-container fluid class="pb-4 px-lg-4">
      <b-row>
        <b-col>
          <section
            class="success-payment d-flex justify-content-center align-items-center flex-column"
            v-if="paymentSuccess"
          >
            <h3 class="h3 font-bold mb-2 text-center">
              ¡Felicidades!
            </h3>
            <p>Es momento de llevar tu negocio al siguiente nivel.</p>
            <button
              type="button"
              class="btn md__btn-primary px-5 my-4 center"
              name=""
              @click.prevent="goPage('dashboard')"
            >
              Gestionar Negocio
            </button>
          </section>

          <section
            class="success-payment d-flex justify-content-center align-items-center flex-column"
            v-if="paymentError"
          >
            <h3 class="h3 font-bold mb-2 text-center">
              Ups!
            </h3>
            <p class="text-center">
              No se ha podido completar la validación del pago, <br />por favor
              intente nuevamente o escriba a
              <span class="font-bold text-color-secondary"
                >soporte@mydessk.com</span
              >
            </p>
          </section>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import payService from "../services/payService";

export default {
  name: "PlansCheckouts",
  data() {
    return {
      clientTransactionId: null,
      id: null,
      cardToken: null,
      paymentSuccess: false,
      paymentError: false
    };
  },
  methods: {
    goPage(name) {
      this.$router.push({ name });
    },
    async checkoutPpSubscription() {
      this.$store.dispatch("toggleRequestLoading", {
        text: "Por favor espere un momento, estamos validando la transacción."
      });
      const data = {
        client: this.clientTransactionId,
        transaction: this.id,
        cardToken: this.cardToken
      };
      try {
        let response = await payService
          .checkoutPpSubscription(data)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;

        if (success) {
          this.paymentSuccess = true;
          this.$notifications.success({
            message
          });
        } else {
          this.paymentError = true;
        }
      } catch (error) {
        this.paymentError = true;
        return false;
      }
    }
  },
  computed: {},
  props: {},
  mounted() {
    const { id, clientTransactionId, ctoken } = this.$route.query;

    if (id && clientTransactionId) {
      this.id = id;
      this.clientTransactionId = clientTransactionId;
      this.cardToken = ctoken || null;

      this.checkoutPpSubscription();
    }
  }
};
</script>

<style lang="scss" scoped></style>
